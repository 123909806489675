import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadClasses as load } from '../store/classesSlice';
import { filterSearch } from '../utils';

export const useClasses = () => {
  const dispatch = useDispatch();

  const classes = useSelector((state) => state.classes);
  const taggedClasses = useSelector((state) => state.taggedClasses);
  const tags = useMemo(() => Object.keys(taggedClasses), [taggedClasses]);

  const loadClasses = () => dispatch(load());
  const filterClasses = (term, ignoredItemIds = []) =>
    filterSearch(tags, taggedClasses, classes, term, ignoredItemIds);

  return {
    classes,
    loadClasses,
    filterClasses,
  };
};
