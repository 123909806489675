import './TreasureLevelSelector.sass';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import {
  faCaretDown,
  faCaretUp,
  faCrown,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'bootstrap';
import classNames from 'classnames';
import { TreasureIcon } from './TreasureIcon';

export const TreasureLevelSelector = (props) => {
  const {
    className,
    treasureId,
    value,
    maxValue,
    hoverCardSide,
    onChangeValue,
    locked,
    promotes,
  } = props;
  const crownIconRef = useRef();

  // * Ensure integer is used for max.
  console.assert(
    Number.isInteger(maxValue),
    'maxValue must be a valid integer.',
  );

  const onChange = (val) => {
    if (![null, undefined, NaN].includes(typeof val)) {
      const newVal = Math.min(maxValue, Math.max(0, val));
      onChangeValue?.(newVal);
    }
  };

  // If there's only one level, make it a toggle-able icon.
  const singleLevel = maxValue === 1;

  useEffect(() => {
    const { current } = crownIconRef;
    if (current) {
      const tooltip = new Tooltip(current);
      return () => {
        tooltip.dispose();
      };
    }
  }, [crownIconRef.current]);

  return (
    <div
      className={classNames('treasure-level-selector', className)}
      role={singleLevel && !locked ? 'button' : undefined}
      onClick={singleLevel && !locked ? () => onChange(!value) : undefined}
    >
      <TreasureIcon
        treasureId={treasureId}
        greyedOut={!value}
        className={classNames('icon', { 'max-level': maxValue <= value })}
        containerClassName={classNames('icon-container', {
          button: singleLevel && !locked,
        })}
        side={hoverCardSide}
      >
        {locked && (
          <FontAwesomeIcon className='lock-icon' icon={faLock} size='2xs' />
        )}
        {promotes && (
          <FontAwesomeIcon
            className='crown-icon'
            icon={faCrown}
            size='2xs'
            ref={crownIconRef}
            data-bs-toggle='tooltip'
            data-bs-title={`Promotes ${promotes}.`}
            data-bs-placement='right'
            data-bs-custom-class='tooltip'
          />
        )}
        {!singleLevel && (
          <>
            <div className='level'>{value ? value : null}</div>
            {!locked && (
              <div className='d-flex flex-column justify-content-center controls'>
                <div
                  role='button'
                  className='button up'
                  onClick={() => onChange((value ?? 0) + 1)}
                >
                  <FontAwesomeIcon icon={faCaretUp} size='3x' />
                </div>
                <div
                  role='button'
                  className='button down'
                  onClick={
                    !locked ? () => onChange((value ?? 0) - 1) : undefined
                  }
                >
                  <FontAwesomeIcon icon={faCaretDown} size='3x' />
                </div>
              </div>
            )}
          </>
        )}
      </TreasureIcon>
    </div>
  );
};

TreasureLevelSelector.propTypes = {
  className: PropTypes.string,
  treasureId: PropTypes.string.isRequired,
  value: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
  hoverCardSide: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  onChangeValue: PropTypes.func,
  locked: PropTypes.bool,
  promotes: PropTypes.string,
};
