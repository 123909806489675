import './TreasureIcon.sass';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import classNames from 'classnames';
import { TreasureCard } from './TreasureCard';
import { TreasureImage } from './TreasureImage';
import { useTreasures } from '../hooks';

export const TreasureIcon = (props) => {
  const {
    treasureId,
    greyedOut,
    className,
    containerClassName,
    children,
    side,
    ...rest
  } = props;
  const { treasures } = useTreasures();
  const iconContainerRef = useRef();
  const [show, setShow] = useState();

  // * Support touch screen.
  // * Reference: https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
  useEffect(() => {
    const current = iconContainerRef.current;
    if (current && 'ontouchstart' in window) {
      const handleTouch = (e) => {
        setShow(current.contains(e.target) ? true : undefined);
      };

      // * In the event a device supports both mouse and touch, moving the mouse
      // * into another card should hide the hover card for the current card.
      const handleMouseEnter = (e) => {
        if (show && !current.contains(e.target)) {
          setShow(undefined);
        }
      };

      document.addEventListener('touchstart', handleTouch);
      document.addEventListener('mouseenter', handleMouseEnter);
      return () => {
        document.removeEventListener('touchstart', handleTouch);
        document.removeEventListener('mouseenter', handleMouseEnter);
      };
    }
  }, []);

  return (
    <HoverCard.Root openDelay={0} closeDelay={0} open={show}>
      <HoverCard.Trigger>
        <div ref={iconContainerRef} className={containerClassName}>
          <TreasureImage
            treasureId={treasureId}
            className={classNames('treasure-icon', className, {
              'greyed-out': greyedOut,
            })}
            {...rest}
          />
          {children}
        </div>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content align='start' side={side} style={{ zIndex: 99 }}>
          {(() => {
            const treasure = treasures[treasureId];

            return treasure ? (
              <TreasureCard id={treasureId} {...treasure} />
            ) : null;
          })()}
          <HoverCard.Arrow width={16} height={10} />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

TreasureIcon.propTypes = {
  treasureId: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  side: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  greyedOut: PropTypes.bool,
};
