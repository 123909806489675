import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PARTY_SIZE_LIMIT } from '../constants';
import {
  clearAll,
  loadBuild,
  loadFromStorage,
  saveToStorage,
  updateClasses,
  updateId,
  updateLevel,
  updateName,
  updateTreasures,
} from '../store/buildsSlice';

export const useCurrentBuild = () => {
  const dispatch = useDispatch();

  const currentBuild = useSelector((state) => state.currentBuild);
  const {
    id: currentId,
    name: currentName,
    level: currentLevel,
    classes: currentClasses,
    treasures: currentTreasures,
  } = currentBuild;
  const treasures = useSelector((state) => state.treasures);
  const classes = useSelector((state) => state.classes);
  const loadRecentFromStorage = () => dispatch(loadFromStorage());

  const maxedTreasures = useMemo(() => {
    return Object.entries(currentTreasures)
      .filter(([id, level]) => treasures[id].levels <= level)
      .map(([id]) => id);
  }, [currentTreasures, treasures]);

  const promotedClasses = useMemo(() => {
    return currentClasses
      .map((id) => {
        const { supportTreasures, promotion: { name: promotionName } = {} } =
          classes[id] ?? {};
        return supportTreasures?.every(
          (treasureId) => currentTreasures[treasureId] > 0,
        )
          ? promotionName
          : undefined;
      })
      .filter((c) => c);
  }, [currentClasses, currentTreasures, classes]);

  const classDomains = useMemo(() => {
    return currentClasses.reduce((aggregate, id) => {
      const { classDomains } = classes[id] ?? {};
      classDomains.forEach((domain) => {
        aggregate[domain] = (aggregate[domain] ?? 0) + 1;
      });
      return aggregate;
    }, {});
  }, [currentClasses, classes]);

  const classAbilityDomains = useMemo(() => {
    return currentClasses.reduce((aggregate, id) => {
      const { abilityDomains } = classes[id] ?? {};
      abilityDomains.forEach((domain) => {
        aggregate[domain] = (aggregate[domain] ?? 0) + 1;
      });
      return aggregate;
    }, {});
  }, [currentClasses, classes]);

  const treasureDomains = useMemo(() => {
    return Object.entries(currentTreasures).reduce((aggregate, [id, level]) => {
      if (level) {
        const { treasureDomain } = treasures[id] ?? {};
        aggregate[treasureDomain] = (aggregate[treasureDomain] ?? 0) + 1;
      }
      return aggregate;
    }, {});
  }, [currentTreasures, treasures]);

  const treasureAbilityDomains = useMemo(() => {
    return Object.entries(currentTreasures).reduce((aggregate, [id, level]) => {
      if (level) {
        const { abilityDomains } = treasures[id] ?? {};
        abilityDomains?.forEach((domain) => {
          aggregate[domain] = (aggregate[domain] ?? 0) + 1;
        });
      }
      return aggregate;
    }, {});
  }, [currentTreasures, treasures]);

  const promotionTreasures = useMemo(() => {
    return currentClasses
      .map((id) => classes[id])
      .reduce((aggregate, { supportTreasures, name }) => {
        supportTreasures.forEach((id) => (aggregate[id] = name));
        return aggregate;
      }, {});
  }, [currentClasses, classes]);

  const isNotAvailableAtLevel = (treasureId, selectedLevel) => {
    const { obtain: { start, end } = {} } = treasures[treasureId] ?? {};

    // Ignore non-level-up treasures.
    if (!start || !end) {
      return false;
    }

    // Ensure starting level is within item's range.
    if (!(start <= currentLevel && currentLevel <= end + 1)) {
      return true;
    }

    const levelDiff =
      selectedLevel - parseInt(currentTreasures[treasureId] ?? 0);
    const newLevel = currentLevel + levelDiff;

    // Get the lowest allowable level by getting the start of the highest tier treasure.
    const lowerLimit = Math.max(
      ...Object.keys(currentTreasures).map(
        (id) => treasures[id]?.obtain.start ?? 0,
      ),
    );

    // Total the selected treasure levels below the minimum level
    // based off the current total treasure/build level.
    // ex. If the current level is 17 and the highest range of selectable
    //     treasures are between 11 - 25, then the player can decrease
    //     treasures whose starting ranges are below 11 up-to and including
    //     11 before they have to decrease the levels of treasures in the
    //     11 - 25 range.
    const levelsBelowLowerLimit = Object.entries(currentTreasures).reduce(
      (aggregate, [id, level]) => {
        const { obtain: { start, end } = {} } = treasures[id] ?? {};
        if (!start || !end) {
          return aggregate;
        }

        const treasureLevel = id === treasureId ? selectedLevel : level;

        return aggregate + (start < lowerLimit ? treasureLevel : 0);
      },
      1, // * Player level starts at 1, not 0.
    );

    // Do not allow changes that are too extreme.
    return (
      newLevel < lowerLimit ||
      // * When decreasing, ensure the selected treasure go below the current min level.
      (levelDiff < 0 && levelsBelowLowerLimit < lowerLimit) ||
      // * When increasing, ensure the current level isn't over the upper limit of the tier.
      (levelDiff > 0 && currentLevel > end)
    );
  };

  const bossAlreadySelected = (treasureId) => {
    const { obtain: { boss: bossNumber } = {} } = treasures?.[treasureId] ?? {};

    if (bossNumber) {
      return !!Object.entries(currentTreasures).find(([id, level]) => {
        const { obtain: { boss } = {} } = treasures?.[id] ?? {};

        return boss && boss === bossNumber && level > 0;
      });
    }

    return false;
  };

  const questAlreadySelected = (treasureId) => {
    const { obtain: { quest: questNumber } = {} } =
      treasures?.[treasureId] ?? {};

    if (questNumber) {
      return !!Object.entries(currentTreasures).find(([id, level]) => {
        const { obtain: { quest } = {} } = treasures?.[id] ?? {};

        return quest && quest === questNumber && level > 0;
      });
    }

    return false;
  };

  const validate = (treasureId, selectedLevel) => {
    const levelIncrease =
      parseInt(currentTreasures[treasureId] ?? 0) < selectedLevel;

    if (isNotAvailableAtLevel(treasureId, selectedLevel)) {
      return false;
    }

    if (bossAlreadySelected(treasureId) && levelIncrease) {
      return false;
    }

    if (questAlreadySelected(treasureId) && levelIncrease) {
      return false;
    }

    return true;
  };

  const attemptUpdateTreasures = (treasuresMap) => {
    let newLevel = currentLevel;
    const toUpdate = Object.entries(treasuresMap)
      .filter(([id, level]) => validate(id, level))
      .reduce((aggregate, [id, level]) => {
        // If this is a level up treasure, update the party level.
        const { obtain: { start, end } = {} } = treasures[id] ?? {};
        if (start && end) {
          newLevel += level - parseInt(currentTreasures[id] ?? 0);
        }

        return {
          ...aggregate,
          [id]: level,
        };
      }, {});

    const newTreasures = Object.entries(currentTreasures)
      .filter(([id]) => !(id in toUpdate))
      .reduce(
        (aggregate, [id, level]) => ({
          ...aggregate,
          [id]: level,
        }),
        Object.entries(toUpdate)
          .filter(([_id, level]) => !!level)
          .reduce(
            (aggregate, [id, level]) => ({ ...aggregate, [id]: level }),
            {},
          ),
      );

    dispatch(updateTreasures(newTreasures));
    dispatch(updateLevel(newLevel));
    dispatch(saveToStorage());
  };

  const attemptUpdateTreasure = (treasureId, selectedLevel) => {
    attemptUpdateTreasures({ [treasureId]: selectedLevel });
  };

  const addClass = (id) => {
    // Only add class if there's room.
    if (currentClasses.length < PARTY_SIZE_LIMIT) {
      // Ensure no dupes.
      const newClasses = new Set(currentClasses ?? []);
      newClasses.add(id);
      dispatch(updateClasses(Array.from(newClasses)));
      dispatch(saveToStorage());
    }
  };

  const removeClass = (id) => {
    dispatch(updateClasses(currentClasses.filter((c) => c !== id)));
    dispatch(saveToStorage());
  };

  const changeId = (id) => {
    dispatch(updateId(id));
    dispatch(saveToStorage());
  };

  const changeName = (name) => {
    dispatch(updateName(name));
    dispatch(saveToStorage());
  };

  const sanitize = (buildData) => {
    const {
      id,
      name,
      classes: buildClasses,
      treasures: buildTreasures,
    } = buildData ?? {};

    // * Type checks.
    const safeId =
      typeof id === 'string' && /[A-Za-z0-9_-]{21}/.test(id) ? id : undefined;
    const safeName = typeof name === 'string' ? name : undefined;
    const classList =
      buildClasses && Array.isArray(buildClasses) ? buildClasses : [];
    const treasureMap =
      typeof buildTreasures === 'object' ? buildTreasures : {};

    const safeClasses = classList.filter((id) => id in classes);
    const safeTreasures = Object.entries(treasureMap)
      .filter(([id, level]) => id in treasures && level > 0)
      .reduce((aggregate, [id, level]) => {
        const { levels } = treasures[id] ?? {};
        if (!levels) {
          return aggregate;
        }

        return {
          ...aggregate,
          [id]: Math.max(1, Math.min(levels, level)),
        };
      }, {});

    // * Calculate level from treasures.
    const safeLevel = Object.entries(safeTreasures).reduce(
      (total, [id, level]) => {
        const { obtain: { start, end } = {} } = treasures[id] ?? {};
        return start && end ? total + level : total;
      },
      1,
    );

    return {
      ...(safeId ? { id: safeId } : {}),
      ...(safeName ? { name: safeName } : {}),
      classes: safeClasses,
      treasures: safeTreasures,
      level: safeLevel,
    };
  };

  const load = (buildData) => {
    dispatch(loadBuild(sanitize(buildData)));
    dispatch(saveToStorage());
  };
  const clear = () => {
    dispatch(clearAll());
    dispatch(saveToStorage());
  };

  return {
    currentBuild,
    currentId,
    currentName,
    currentLevel,
    currentClasses,
    currentTreasures,
    maxedTreasures,
    promotedClasses,
    classDomains,
    classAbilityDomains,
    treasureDomains,
    treasureAbilityDomains,
    promotionTreasures,
    attemptUpdateTreasure,
    attemptUpdateTreasures,
    addClass,
    removeClass,
    changeId,
    changeName,
    load,
    loadRecentFromStorage,
    clear,
  };
};
