import PropTypes from 'prop-types';

export const FilterInput = (props) => {
  const { placeholder, onChange } = props;

  return (
    <div className='input-group'>
      <input
        className='form-control'
        placeholder={placeholder ?? 'search'}
        onChange={({ target }) => {
          onChange(target?.value);
        }}
      />
    </div>
  );
};

FilterInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
