import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppLayout } from './components/AppLayout';
import { ClassScreen } from './components/ClassScreen';
import { PlannerScreen } from './components/PlannerScreen';
import { TreasureScreen } from './components/TreasureScreen';
import { useBuilds, useClasses, useCurrentBuild, useTreasures } from './hooks';

function App() {
  const { loadClasses } = useClasses();
  const { loadTreasures } = useTreasures();
  const { loadBuilds } = useBuilds();
  const { loadRecentFromStorage } = useCurrentBuild();

  useEffect(() => {
    loadClasses();
    loadTreasures();
    loadBuilds();
    loadRecentFromStorage();
  }, []);

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route index element={<Navigate to='/classes' replace />} />
        <Route path='/Planner' element={<PlannerScreen />} />
        <Route path='/classes' element={<ClassScreen />} />
        <Route path='/treasures' element={<TreasureScreen />} />
      </Route>
    </Routes>
  );
}

export default App;
