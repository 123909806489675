import './TreasureCard.sass';
import PropTypes from 'prop-types';
import { AbilityEffectDisplay } from './AbilityEffectDisplay';
import { DomainDisplay } from './DomainDisplay';
import { enhance as e } from './EnhanceText';
import { ObtainedFromDisplay } from './ObtainedFromDisplay';
import { TreasureImage } from './TreasureImage';

const TREASURE_ICON_SIZE = '100em';

export const TreasureCard = (props) => {
  const {
    id,
    name,
    description,
    levels,
    obtain,
    treasureDomain,
    abilityDomains,
    effect,
  } = props;

  return (
    <div className='treasure-card d-flex flex-row gap-4'>
      <div className='d-flex flex-column align-items-center gap-2'>
        <span className='name text-center'>{name}</span>
        <div className='image'>
          <TreasureImage treasureId={id} width={TREASURE_ICON_SIZE} />
        </div>
        <span className='title-text nowrap'>Max Level {levels}</span>
      </div>
      <div className='d-flex flex-column p-1 gap-3'>
        <div className='d-flex flex-row gap-4'>
          {obtain && (
            <div className='d-flex flex-column'>
              <span className='heading nowrap'>Obtained From</span>
              <div className='content-text'>
                <ObtainedFromDisplay {...obtain} />
              </div>
            </div>
          )}
          {treasureDomain && (
            <div className='d-flex flex-column'>
              <span className='heading nowrap'>Treasure Domain</span>
              <div className='content-text'>
                <DomainDisplay domains={[treasureDomain]} />
              </div>
            </div>
          )}
          {abilityDomains && (
            <div className='d-flex flex-column'>
              <span className='heading nowrap'>Ability Domain</span>
              <DomainDisplay domains={abilityDomains} />
            </div>
          )}
        </div>
        <div>{e(description)}</div>
        <div className='content-text'>
          <AbilityEffectDisplay {...effect} />
        </div>
      </div>
    </div>
  );
};

const rangeType = PropTypes.shape({
  start: PropTypes.number,
  end: PropTypes.number,
});

const attackType = PropTypes.shape({
  start: PropTypes.number,
  end: PropTypes.number,
  scale: PropTypes.shape({
    value: PropTypes.number,
    heroes: PropTypes.arrayOf(PropTypes.string),
  }),
});

TreasureCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  levels: PropTypes.number.isRequired,
  obtain: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    boss: PropTypes.number,
    quest: PropTypes.number,
    chest: PropTypes.bool,
  }),
  treasureDomain: PropTypes.string.isRequired,
  effect: PropTypes.shape({
    physical: PropTypes.oneOfType([PropTypes.number, attackType]),
    magical: PropTypes.oneOfType([PropTypes.number, attackType]),
    cooldown: PropTypes.oneOfType([PropTypes.number, rangeType]),
    mp: PropTypes.oneOfType([PropTypes.number, rangeType]),
  }),
};
