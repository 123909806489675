import './PlannerFilter.sass';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ClassIcon } from './ClassIcon';
import { FilterInput } from './FilterInput';
import { TreasureIcon } from './TreasureIcon';
import { useClasses, useCurrentBuild, useTreasures } from '../hooks';

const SEARCH_ICON_SIZE = '60';

export const PlannerFilter = (props) => {
  const { className } = props;
  const { classes: allClasses, filterClasses } = useClasses();
  const { treasures: allTreasures, filterTreasures } = useTreasures();
  const {
    currentClasses,
    currentTreasures,
    maxedTreasures,
    attemptUpdateTreasure,
    addClass,
  } = useCurrentBuild();

  const [foundClasses, setFoundClasses] = useState(allClasses);
  const [foundTreasures, setFoundTreasures] = useState(allTreasures);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setFoundClasses(
      searchText
        ? filterClasses(searchText, currentClasses)
        : Object.entries(allClasses)
            .filter(([id]) => !currentClasses.includes(id))
            .reduce((a, [id, c]) => ({ ...a, [id]: c }), {}),
    );
    setFoundTreasures(
      searchText
        ? filterTreasures(searchText, maxedTreasures)
        : Object.entries(allTreasures)
            .filter(([id]) => !maxedTreasures.includes(id))
            .reduce((a, [id, t]) => ({ ...a, [id]: t }), {}),
    );
  }, [searchText, currentClasses, maxedTreasures, allClasses, allTreasures]);

  return (
    <div
      className={classNames(
        'planner-filter d-flex flex-column gap-2',
        className,
      )}
    >
      <FilterInput
        placeholder='filter classes & treasures'
        onChange={setSearchText}
      />
      {foundClasses && (
        <div className='scrollable d-flex flex-row gap-2'>
          {Object.keys(foundClasses).map((id, i) => (
            <div
              key={i}
              role='button'
              className='select-icon'
              onClick={() => {
                addClass(id);
              }}
            >
              <ClassIcon classId={id} height={SEARCH_ICON_SIZE} />
            </div>
          ))}
        </div>
      )}
      {foundTreasures && (
        <div className='scrollable d-flex flex-row gap-2'>
          {Object.keys(foundTreasures).map((id, i) => (
            <div
              key={i}
              role='button'
              className='select-icon'
              onClick={() => {
                attemptUpdateTreasure(id, (currentTreasures[id] ?? 0) + 1);
              }}
            >
              <TreasureIcon treasureId={id} height={SEARCH_ICON_SIZE} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

PlannerFilter.propTypes = {
  className: PropTypes.string,
};
