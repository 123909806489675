import './Nav.sass';
import { Link } from 'react-router-dom';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Nav = () => {
  return (
    <nav className='nav navbar py-0'>
      <div
        className='d-flex flex-row justify-content-between align-items-center'
        style={{
          width: '100vw',
        }}
      >
        <div className='d-flex flex-row'>
          <Link aria-label='home' className='navbar-brand ms-3' to='/'>
            <FontAwesomeIcon icon={faSeedling} />
          </Link>
          <Link className='nav-link' to='/planner'>
            Build Planner
          </Link>
          <Link className='nav-link' to='/classes'>
            Classes
          </Link>
          <Link className='nav-link' to='/treasures'>
            Treasures
          </Link>
        </div>
      </div>
    </nav>
  );
};
