import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { Tooltip } from 'bootstrap';

export const TooltipButton = (props) => {
  const {
    className,
    flat,
    children,
    onClick,
    tooltipMessage,
    tooltipClassName,
    tooltipPlacement,
    tooltipTrigger,
    tooltipDelay,
  } = props;
  const buttonRef = useRef();

  useEffect(() => {
    const { current } = buttonRef;
    if (current) {
      const tooltip = new Tooltip(current, {
        ...(tooltipMessage ? { title: tooltipMessage } : {}),
        ...(tooltipPlacement ? { placement: tooltipPlacement } : {}),
        ...(tooltipClassName ? { customClass: tooltipClassName } : {}),
        ...(tooltipTrigger ? { trigger: tooltipTrigger } : {}),
        ...(tooltipDelay ? { delay: tooltipDelay } : {}),
      });

      // * Bug in Bootstrap. Does not hide when 'click' is used.
      // * We enforce it here.
      let shownListener;
      if (tooltipTrigger?.includes('click') && tooltipDelay?.hide) {
        shownListener = () => {
          setTimeout(() => {
            tooltip.hide();
          }, tooltipDelay.hide);
        };
        current.addEventListener('shown.bs.tooltip', shownListener);
      }

      return () => {
        if (shownListener) {
          current.removeEventListener('shown.bs.tooltip', shownListener);
        }
        tooltip.dispose();
      };
    }
  }, [buttonRef.current]);

  const buttonProps = {
    ref: buttonRef,
    className,
    onClick,
    children,
  };

  return flat ? (
    <div role='button' {...buttonProps} />
  ) : (
    <button {...buttonProps} />
  );
};

TooltipButton.propTypes = {
  flat: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  tooltipMessage: PropTypes.string.isRequired,
  tooltipClassName: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
  tooltipTrigger: PropTypes.string, // * Any combination of 'click, hover, focus, manual'.
  tooltipDelay: PropTypes.shape({
    show: PropTypes.number,
    hide: PropTypes.number,
  }),
};
