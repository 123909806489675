import { createSlice } from '@reduxjs/toolkit';
import * as ls from 'local-storage';

const buildsSlice = createSlice({
  name: 'builds',
  initialState: {},
  reducers: {
    update: {
      reducer: (state, action) => {
        const { id, name, classes, treasures } = action.payload ?? {};
        state[id] = {
          name,
          classes,
          treasures,
        };
      },
      prepare: (id, name, classes, treasures) => ({
        payload: {
          id,
          name,
          classes,
          treasures,
        },
      }),
    },
    remove: {
      reducer: (state, action) => {
        const id = action.payload;
        delete state[id];
      },
      prepare: (id) => ({
        payload: id,
      }),
    },
    load: {
      reducer: () => {
        return ls.get('builds') ?? {};
      },
    },
    save: {
      reducer: (state) => {
        ls.set('builds', state);
      },
    },
  },
});

export const { update, remove, load, save } = buildsSlice.actions;
export const buildsReducer = buildsSlice.reducer;

const currenBuildInitialState = {
  level: 1,
  classes: [],
  treasures: {},
};

const currentBuildSlice = createSlice({
  name: 'current_build',
  initialState: currenBuildInitialState,
  reducers: {
    loadBuild: {
      reducer: (_state, action) => ({
        ...action.payload,
      }),
      prepare: (build) => ({
        payload: build,
      }),
    },
    loadFromStorage: {
      reducer: () => {
        return ls.get('current_build') ?? currenBuildInitialState;
      },
    },
    saveToStorage: {
      reducer: (state) => {
        ls.set('current_build', state);
      },
    },
    updateId: {
      reducer: (state, action) => {
        state.id = action.payload;
      },
      prepare: (id) => ({
        payload: id,
      }),
    },
    updateName: {
      reducer: (state, action) => {
        state.name = action.payload;
      },
      prepare: (name) => ({
        payload: name,
      }),
    },
    updateLevel: {
      reducer: (state, action) => {
        state.level = action.payload;
      },
      prepare: (level) => ({
        payload: level,
      }),
    },
    updateClasses: {
      reducer: (state, action) => {
        state.classes = action.payload;
      },
      prepare: (classes) => ({
        payload: classes,
      }),
    },
    updateTreasures: {
      reducer: (state, action) => {
        state.treasures = action.payload;
      },
      prepare: (treasures) => ({
        payload: treasures,
      }),
    },
    clearAll: {
      reducer: () => currenBuildInitialState,
    },
  },
});

export const {
  loadBuild,
  loadFromStorage,
  saveToStorage,
  updateId,
  updateName,
  updateLevel,
  updateClasses,
  updateTreasures,
  clearAll,
} = currentBuildSlice.actions;
export const currentBuildReducer = currentBuildSlice.reducer;
