import PropTypes from 'prop-types';
import { enhance as e } from './EnhanceText';

export const ObtainedFromDisplay = (props) => {
  const { start, end, boss, quest, chest } = props ?? {};

  return (
    <span className='nowrap'>
      {start && end && e(`Levels ${start} to ${end}`)}
      {boss && e(`BOSS ${boss}`)}
      {quest && e(`QUEST ${quest}`)}
      {chest && e('CHESTS')}
    </span>
  );
};

ObtainedFromDisplay.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  boss: PropTypes.number,
  quest: PropTypes.number,
  chest: PropTypes.bool,
};
