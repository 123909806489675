import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { buildsReducer, currentBuildReducer } from './buildsSlice';
import { classesReducer, taggedClassesReducer } from './classesSlice';
import { taggedTreasuresReducer, treasuresReducer } from './treasuresSlice';

export const createStore = ({ preloadedState = {} } = {}) =>
  configureStore({
    reducer: {
      classes: classesReducer,
      taggedClasses: taggedClassesReducer,
      treasures: treasuresReducer,
      taggedTreasures: taggedTreasuresReducer,
      builds: buildsReducer,
      currentBuild: currentBuildReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    preloadedState,
  });

export const store = createStore();

setupListeners(store.dispatch);
