import './JsonTextArea.sass';
import PropTypes from 'prop-types';
import { useId, useState } from 'react';
import { faCopy, faPaste } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { TooltipButton } from './TooltipButton';

export const JsonTextArea = (props) => {
  const { className, label, value, onChange } = props;
  const textAreaId = useId();
  const [error, setError] = useState();

  const data = value ? JSON.stringify(value) : '';

  const onPaste = (pasteData) => {
    if (pasteData) {
      try {
        const result = JSON.parse(pasteData);
        setError(undefined);
        onChange?.(result);
      } catch (e) {
        if (e instanceof SyntaxError) {
          setError('Invalid.');
        }
      }
    }
  };

  return (
    <div className={classNames('json-text-area d-flex flex-column', className)}>
      {label && <label htmlFor={textAreaId}>{label}</label>}
      <div className='text-area'>
        <div className='buttons d-flex flex-row gap-2'>
          <TooltipButton
            flat
            tooltipTrigger='click'
            tooltipMessage='Pasted!'
            tooltipDelay={{
              hide: 400,
            }}
            onClick={async () => {
              onPaste(await navigator.clipboard.readText());
            }}
          >
            <FontAwesomeIcon icon={faPaste} />
          </TooltipButton>
          <TooltipButton
            flat
            tooltipTrigger='click'
            tooltipMessage='Copied!'
            tooltipDelay={{
              hide: 400,
            }}
            onClick={() => {
              navigator.clipboard.writeText(data);
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
          </TooltipButton>
        </div>
        <textarea
          id={textAreaId}
          value={data}
          onPaste={({ clipboardData }) => {
            onPaste(clipboardData.getData('Text'));
          }}
          readOnly
        />
      </div>
      {error && <span className='text-danger'>{error}</span>}
    </div>
  );
};

JsonTextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
