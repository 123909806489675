import { createAction, createSlice } from '@reduxjs/toolkit';
import treasuresData from '../data/treasures.json';
import { buildTags } from '../utils';

export const loadTreasures = createAction('treasures/load', () => {
  return {
    payload: treasuresData,
  };
});

const treasuresSlice = createSlice({
  name: 'treasures',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTreasures, (_state, action) => {
      return action.payload;
    });
  },
});

export const {} = treasuresSlice.actions;
export const treasuresReducer = treasuresSlice.reducer;

const taggedTreasuresSlice = createSlice({
  name: 'tagged_treasures',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTreasures, (_state, action) => {
      const { payload } = action;
      return buildTags(
        payload,
        (
          {
            name,
            levels,
            obtain: {
              start: obtainStart,
              end: obtainEnd,
              boss,
              quest,
              chest,
            } = {},
            treasureDomain,
            abilityDomains,
            effect,
            tags,
          },
          builder,
        ) => {
          builder.add(name);
          builder.add(levels);

          // Add all levels between the start and end, inclusive.
          if (obtainStart && obtainEnd) {
            builder.addList(
              Array.from(
                { length: obtainEnd - obtainStart },
                (_value, index) => obtainStart + index,
              ),
            );
          }

          if (boss) {
            builder.add(`boss ${boss}`);
          }

          if (quest) {
            builder.add(`quest ${quest}`);
          }

          if (chest) {
            builder.add('chests');
          }

          builder.add(treasureDomain);
          builder.addList(abilityDomains);

          if (effect) {
            builder.addList(
              Object.keys(effect).map((c) => {
                switch (c) {
                  case 'physical':
                    return 'physical attack';
                  case 'magical':
                    return 'magical attack';
                }
              }),
            );

            builder.addList(effect?.physical?.scale?.heroes);
            builder.addList(effect?.magical?.scale?.heroes);
          }

          builder.addList(tags);
        },
      );
    });
  },
});

export const {} = taggedTreasuresSlice.actions;
export const taggedTreasuresReducer = taggedTreasuresSlice.reducer;
