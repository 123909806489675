import './NameInput.sass';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { faCheck, faEdit, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export const NameInput = (props) => {
  const { className, placeholder = 'enter text', value, onChange } = props;

  const [typingValue, setTypingValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const inputRef = useRef();

  const submitEdit = () => {
    onChange?.(typingValue);
    setEditMode(false);
  };

  const cancelEdit = () => {
    setTypingValue(value);
    setEditMode(false);
  };

  useEffect(() => {
    const { current } = inputRef;
    if (editMode && current) {
      current.focus();
    }
  }, [editMode, inputRef.current]);

  return (
    <div className={classNames('name-input', className)}>
      {!editMode && (
        <div
          className='text d-flex flex-row align-items-start gap-2'
          onMouseEnter={() => setShowEditButton(true)}
          onMouseLeave={() => setShowEditButton(false)}
        >
          <span className={classNames({ 'placeholder-display': !value })}>
            {value ? value : placeholder}
          </span>
          {showEditButton && (
            <button
              className='edit'
              onClick={() => {
                setEditMode(true);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}
        </div>
      )}
      {editMode && (
        <div className='d-flex flex-row gap-2'>
          <input
            ref={inputRef}
            type='text'
            placeholder={placeholder}
            defaultValue={value ?? ''}
            onChange={({ target }) => {
              setTypingValue(target?.value);
            }}
            onKeyDown={({ code }) => {
              switch (code) {
                case 'Enter':
                  return submitEdit();
                case 'Escape':
                  return cancelEdit();
              }
            }}
          />
          <button className='submit' onClick={submitEdit}>
            <FontAwesomeIcon icon={faCheck} />
          </button>
          <button className='cancel' onClick={cancelEdit}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}
    </div>
  );
};

NameInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
