/**
 * Attempts to set a value in a collection.
 * If the key already exists, it will add the
 * value to the existing entry's array.
 * @param {Object} collection
 * @param {string} key
 * @param {*} value
 * @example
 * // returns { magic: ['divine_cross', 'night_wing'] }
 * accumulativeAdd({ magic: ['divine_cross']}, 'magic', 'night_wing');
 */
export const accumulativeAdd = (collection, key, value) => {
  const lowerKey = key?.toString().toLowerCase();
  return {
    ...collection,
    [lowerKey]: [
      ...(lowerKey in collection ? collection[lowerKey] : []),
      value,
    ],
  };
};

/**
 * Takes a list and a value and adds them to a map
 * with the list elements as the keys with the
 * passed value as their values.
 * @param {Array} list
 * @param {*} value
 * @param {Object} [resultAggregate={}]
 * @example
 * // returns { relic: 'treasure', artifact: 'treasure', symbol: 'treasure' }
 * reduceMap(['relic', 'artifact', 'symbol'], 'treasure');
 */
export const reduceMap = (list, value, resultAggregate = {}) => {
  return list?.reduce(
    (results, item) => accumulativeAdd(results, item, value),
    resultAggregate,
  );
};

/**
 * Allows the creation of a tag collection where,
 * values of each entry can be inverse mapped to their keys.
 * @param {Object} collection
 * @param {Function} callback
 */
export const buildTags = (collection, callback) => {
  if (typeof collection !== 'object') {
    throw new Error('Not a valid collection.');
  }

  if (typeof callback !== 'function') {
    throw new Error('Not a valid function');
  }

  return Object.entries(collection).reduce((previousResult, [id, value]) => {
    let result = previousResult;

    const builder = {
      add: (key) => {
        // Only attempt to add if the key exists.
        if (key) {
          result = accumulativeAdd(result, key, id);
        }
      },
      addList: (keys) => {
        // Only attempt to add if keys exists.
        if (keys) {
          result = reduceMap(keys, id, result);
        }
      },
    };

    callback(value, builder);
    return result;
  }, {});
};

/**
 * Attempt to find partially matching items by selective values.
 * @param {Array} tags
 * @param {Object} tagsToItemIds
 * @param {Object} itemsMap
 * @param {String} terms
 * @param {Array} itemIds
 */
export const filterSearch = (
  tags,
  tagsToItemIds,
  itemsMap,
  searchText,
  ignoredItemIds = [],
  dilimiter = /, */,
) => {
  const terms = searchText
    ?.split(dilimiter)
    .map((t) => t.trim())
    .filter((t) => t);
  if (!terms?.length) {
    return {};
  }

  // Get matching item ids for each term.
  const groupedItemIds = terms?.map((term) => {
    // Finds a full or partial tag match for a term.
    const filteredTags = tags.filter((tag) =>
      tag.includes(term?.toLowerCase()),
    );

    // Get all the item ids for the tags.
    const ids = filteredTags?.reduce((results, tag) => {
      // Remove items that should be ignored.
      const foundItemIds = tagsToItemIds[tag]?.filter(
        (id) => !ignoredItemIds.includes(id),
      );

      return [...results, ...foundItemIds];
    }, []);

    // Return a deduped list of the ids.
    return Array.from(new Set(ids));
  });

  const itemIds = groupedItemIds?.reduce((results, ids, index) => {
    // Set first list as the list to filter off of.
    if (index === 0) {
      return ids;
    }

    // Get item ids that are in common in both lists.
    return Array.from(new Set(ids).intersection(new Set(results)));
  }, []);

  // Return the items.
  return itemIds?.reduce((results, id) => {
    return {
      ...results,
      [id]: itemsMap[id],
    };
  }, {});
};

/**
 * Check if two builds are equal.
 * @param {*} buildA
 * @param {*} buildB
 * @returns
 */
export const areEqual = (buildA, buildB) => {
  if (buildA?.name !== buildB?.name) {
    return false;
  }

  if (new Set(buildA?.classes).difference(new Set(buildB?.classes)).size > 0) {
    return false;
  }

  const aTreasureIds = Object.keys(buildA?.treasures ?? {});
  if (
    new Set(aTreasureIds).difference(
      new Set(Object.keys(buildB?.treasures ?? {})),
    ).size > 0
  ) {
    return false;
  }

  if (
    aTreasureIds.find((id) => buildA?.treasures[id] !== buildB?.treasures[id])
  ) {
    return false;
  }

  return true;
};
