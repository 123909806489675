import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useBuilds, useCurrentBuild } from '../hooks';
import { areEqual } from '../utils';

const DEFAULT_BUILD = { classes: [], treasures: {} };
const DEFAULT_BUILD_OPTION = 'unsaved';

export const BuildsControl = (props) => {
  const { className } = props;
  const { builds, removeBuild } = useBuilds();
  const { currentId, currentName, currentBuild, changeId, load, clear } =
    useCurrentBuild();
  const [unsafePrompt, setUnsafePrompt] = useState();

  const hasChanges =
    (currentId && !areEqual(currentBuild, builds[currentId])) ||
    (!currentId && !areEqual(currentBuild, DEFAULT_BUILD));

  const close = () => {
    setUnsafePrompt(undefined);
  };

  return (
    <>
      <div
        className={classNames(
          'd-flex flex-row justify-content-between',
          className,
        )}
      >
        <div className='d-flex flex-column gap-1'>
          <select
            className='form-select'
            value={currentId ?? DEFAULT_BUILD_OPTION}
            onChange={({ target }) => {
              const { value } = target ?? {};
              if (value !== currentId) {
                const loadBuild = () => {
                  if (value) {
                    load({
                      ...builds[value],
                      id: value,
                    });
                  } else {
                    clear();
                  }
                };
                if (hasChanges) {
                  setUnsafePrompt({
                    action: loadBuild,
                    message: 'You have unsaved changes.',
                    confirm: 'Discard',
                    cancel: 'Cancel',
                  });
                } else {
                  loadBuild();
                }
              }
            }}
          >
            <option value={DEFAULT_BUILD_OPTION}>Choose Saved Builds</option>
            {Object.entries(builds).map(([id, { name }], i) => (
              <option key={i} value={id}>
                {name ?? '- ??? -'}
              </option>
            ))}
          </select>
          <button
            className='btn btn-secondary'
            onClick={() => {
              if (hasChanges) {
                setUnsafePrompt({
                  action: () => {
                    clear();
                  },
                  message: 'You have unsaved changes.',
                  confirm: 'Discard',
                  cancel: 'Cancel',
                });
              } else {
                clear();
              }
            }}
          >
            New Build
          </button>
          <button
            className='btn btn-danger'
            disabled={!currentId}
            onClick={() => {
              setUnsafePrompt({
                action: () => {
                  if (currentId) {
                    removeBuild(currentId);
                  }
                  clear();
                },
                message: `You are about to remove ${currentName ?? 'this build'}?`,
                confirm: 'Delete',
                cancel: 'Cancel',
              });
            }}
          >
            Delete Build
          </button>
        </div>
      </div>
      <Modal show={!!unsafePrompt} onHide={close} data-bs-theme='dark'>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{unsafePrompt?.message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              unsafePrompt?.action();
              close();
            }}
          >
            {unsafePrompt?.confirm}
          </Button>
          <Button variant='secondary' onClick={close}>
            {unsafePrompt?.cancel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

BuildsControl.propTypes = {
  className: PropTypes.string,
};
