import { useState } from 'react';
import { FilterInput } from './FilterInput';
import { TreasureResults } from './TreasureResults';
import { useTreasures } from '../hooks';

export const TreasureScreen = () => {
  const { filterTreasures, treasures } = useTreasures();
  const [filteredTreasures, setFilteredTreasures] = useState(treasures);

  return (
    <div className='d-flex flex-column gap-1'>
      <h1 className='ms-2'>Treasures</h1>
      <div className='ms-1 me-5 mb-3'>
        <div className='mb-1'>
          <FilterInput
            placeholder={`filter treasures, ex. 'javalin' or 'artifact, melee, boss 4'`}
            onChange={(searchText) => {
              setFilteredTreasures(
                searchText ? filterTreasures(searchText) : treasures,
              );
            }}
          />
        </div>
        <span className='ms-1'>
          Filter by treasure name, max levels, obtainable levels, domains, and
          available effects. Filter with multiple terms by separating text with
          commas.
        </span>
      </div>
      <div>
        <TreasureResults treasures={filteredTreasures} />
        {filteredTreasures && !Object.keys(filteredTreasures)?.length && (
          <div>No results.</div>
        )}
      </div>
    </div>
  );
};
