import './ClassResults.sass';
import { ClassImage } from './ClassImage';
import { DomainDisplay } from './DomainDisplay';
import { enhance as e } from './EnhanceText';
import { TreasureIcon } from './TreasureIcon';

const CLASS_ICON_SIZE = 40;
const TREASURE_ICON_SIZE = 50;

export const ClassResults = (props) => {
  const { classes } = props ?? {};

  return (
    <table className='table table-dark class-results'>
      <thead className='sticky-top'>
        <tr>
          <th scope='col'>#</th>
          <th scope='col' className='nowrap'>
            Class & Promotion
          </th>
          <th scope='col' className='nowrap'>
            Class Domain
          </th>
          <th scope='col' className='nowrap'>
            Ability Domain
          </th>
          <th scope='col'>Description</th>
          <th scope='col' className='nowrap'>
            Leadership Skill
          </th>
          <th scope='col' className='nowrap'>
            Support Treasures
          </th>
        </tr>
      </thead>
      <tbody className='table-group-divider'>
        {classes &&
          Object.entries(classes)?.map(
            (
              [
                id,
                {
                  name,
                  classDomains,
                  abilityDomains,
                  promotion,
                  description,
                  supportTreasures,
                },
              ],
              index,
            ) => (
              <tr key={id}>
                <th scope='row'>{index + 1}</th>
                <td>
                  <div className='d-flex flex-row flex-nowrap align-items-center'>
                    <ClassImage classId={id} width={CLASS_ICON_SIZE} />
                    <span className='nowrap ms-2 name'>{name}</span>
                  </div>
                  <div className='d-flex flex-row flex-nowrap align-items-center'>
                    <ClassImage
                      classId={promotion.id}
                      width={CLASS_ICON_SIZE}
                    />
                    <span className='nowrap ms-2 name'>{promotion.name}</span>
                  </div>
                </td>
                <td>
                  <DomainDisplay domains={classDomains} vertical />
                </td>
                <td>
                  <DomainDisplay domains={abilityDomains} vertical />
                </td>
                <td>{e(description)}</td>
                <td>{e(promotion?.leadershipSkill)}</td>
                <td>
                  <div className='d-flex flex-row flex-nowrap gap-1'>
                    {supportTreasures?.map((t, i) => (
                      <TreasureIcon
                        key={i}
                        treasureId={t}
                        width={TREASURE_ICON_SIZE}
                      />
                    ))}
                  </div>
                </td>
              </tr>
            ),
          )}
      </tbody>
    </table>
  );
};
