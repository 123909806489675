import './ClassCard.sass';
import PropTypes from 'prop-types';
import { faArrowDown, faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ClassImage } from './ClassImage';
import { DomainDisplay } from './DomainDisplay';
import { enhance as e } from './EnhanceText';
import { TreasureIcon } from './TreasureIcon';

const CLASS_ICON_SIZE = '70em';
const TREASURE_ICON_SIZE = '43em';

export const ClassCard = (props) => {
  const {
    id,
    name,
    description,
    classDomains,
    abilityDomains,
    promotion,
    supportTreasures,
    acquiredTreasures,
    onTreasureClick,
  } = props;

  console.assert(
    !acquiredTreasures || acquiredTreasures.length === supportTreasures.length,
    'acquiredTreasures and supportTreasures must have the same length.',
  );

  const supportsPromotion = !!acquiredTreasures;
  const promoted = acquiredTreasures?.every((t) => t);

  return (
    <div
      className={classNames('class-card d-flex flex-row gap-4', {
        promoted,
      })}
    >
      {promoted && <FontAwesomeIcon className='crown-icon' icon={faCrown} />}
      <div className='d-flex flex-column gap-2'>
        <div className='d-flex flex-column align-items-center'>
          <span className='name text-center'>{name}</span>
          <ClassImage className='image' classId={id} width={CLASS_ICON_SIZE} />
        </div>
        <FontAwesomeIcon
          className={classNames('name', {
            'greyed-out dark': supportsPromotion && !promoted,
          })}
          icon={faArrowDown}
          size='xl'
        />
        <div className='d-flex flex-column align-items-center'>
          <span className='name text-center'>{promotion?.name}</span>
          <ClassImage
            className='image'
            classId={promotion?.id}
            width={CLASS_ICON_SIZE}
          />
        </div>
      </div>
      <div className='d-flex flex-column p-1 gap-3'>
        <div className='d-flex flex-row gap-3'>
          {classDomains && (
            <div className='d-flex flex-column gap-2'>
              <span className='heading'>CLASS DOMAINS</span>
              <DomainDisplay className='ms-2' domains={classDomains} vertical />
            </div>
          )}
          {abilityDomains && (
            <div className='d-flex flex-column gap-2'>
              <span className='heading'>ABILITY DOMAINS</span>
              <DomainDisplay
                className='ms-2'
                domains={abilityDomains}
                vertical
              />
            </div>
          )}
          {supportTreasures && (
            <div className='d-flex flex-column gap-2'>
              <span className='heading text-center'>SUPPORT TREASURES</span>
              <div className='d-flex flex-row gap-1'>
                {supportTreasures.map((t, i) => (
                  <div
                    key={i}
                    role='button'
                    className='button'
                    onClick={() => {
                      onTreasureClick?.(t);
                    }}
                  >
                    <TreasureIcon
                      treasureId={t}
                      width={TREASURE_ICON_SIZE}
                      height={TREASURE_ICON_SIZE}
                      greyedOut={
                        acquiredTreasures ? !acquiredTreasures[i] : undefined
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className='content-text'>{e(description)}</div>
        {promotion && (
          <div
            className={classNames('d-flex flex-column my-1', {
              'greyed-out dark': supportsPromotion && !promoted,
            })}
          >
            <span className='heading'>{promotion.name} LEADERSHIP SKILL</span>
            <span className='content-text'>{e(promotion.leadershipSkill)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

ClassCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  classDomains: PropTypes.arrayOf(PropTypes.string),
  abilityDomains: PropTypes.arrayOf(PropTypes.string),
  promotion: PropTypes.shape({
    name: PropTypes.string.isRequired,
    leadershipSkill: PropTypes.string.isRequired,
  }),
  supportTreasures: PropTypes.arrayOf(PropTypes.string),
  acquiredTreasures: PropTypes.arrayOf(PropTypes.bool),
  onTreasureClick: PropTypes.func,
};
