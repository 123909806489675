import './PartyRoster.sass';
import PropTypes from 'prop-types';
import { faAnglesUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ClassCard } from './ClassCard';
import { TooltipButton } from './TooltipButton';
import { PARTY_SIZE_LIMIT } from '../constants';
import { useClasses, useCurrentBuild } from '../hooks';

export const PartyRoster = (props) => {
  const { className } = props;
  const { classes: allClasses } = useClasses();
  const {
    currentClasses,
    currentTreasures,
    promotedClasses,
    classDomains,
    classAbilityDomains,
    attemptUpdateTreasure,
    attemptUpdateTreasures,
    removeClass,
  } = useCurrentBuild();

  const textCapitalize = (text) => {
    return String(text).charAt(0).toUpperCase() + String(text).slice(1);
  };

  return (
    <div className={classNames('party-roster d-flex flex-column', className)}>
      <div className='info d-flex flex-column p-3 pb-2 mb-2 sticky-top'>
        <h2>
          Party Roster {currentClasses?.length ?? 0} / {PARTY_SIZE_LIMIT}
        </h2>
        <div className='d-flex flex-column gap-1'>
          {promotedClasses?.length > 0 && (
            <div className='d-flex flex-row gap-2'>
              <span className='heading'>Leadership</span>
              <div className='d-flex flex-row align-items-center flex-wrap gap-1'>
                {promotedClasses.map((c, i) => (
                  <span key={i} className='mastered-class nowrap'>
                    {c}
                  </span>
                ))}
              </div>
            </div>
          )}
          {Object.keys(classDomains)?.length > 0 && (
            <div className='d-flex flex-row gap-2'>
              <span className='heading sub nowrap'>Class Domains</span>
              <div className='d-flex flex-row align-items-center flex-wrap gap-2'>
                {Object.entries(classDomains).map(([domain, count], i) => (
                  <span
                    key={i}
                    className='class-domain nowrap'
                  >{`${textCapitalize(domain)} x${count}`}</span>
                ))}
              </div>
            </div>
          )}
          {Object.keys(classAbilityDomains)?.length > 0 && (
            <div className='d-flex flex-row gap-2'>
              <span className='heading sub nowrap'>Ability Domains</span>
              <div className='d-flex flex-row align-items-center flex-wrap gap-2'>
                {Object.entries(classAbilityDomains).map(
                  ([domain, count], i) => (
                    <span
                      key={i}
                      className='ability-domain nowrap'
                    >{`${textCapitalize(domain)} x${count}`}</span>
                  ),
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {currentClasses?.length > 0 && (
        <div className='d-flex flex-column gap-2 m-3'>
          {currentClasses.map((id, i) => {
            const currentClass = allClasses[id];
            const acquiredTreasures = currentClass?.supportTreasures?.map(
              (t) => currentTreasures?.[t] > 0,
            );
            return (
              <div key={i} className='card-container'>
                <ClassCard
                  id={id}
                  {...currentClass}
                  acquiredTreasures={acquiredTreasures}
                  onTreasureClick={(treasureId) => {
                    if (!(treasureId in currentTreasures)) {
                      attemptUpdateTreasure(treasureId, 1);
                    }
                  }}
                />
                <TooltipButton
                  className='btn btn-sm btn-dark remove'
                  onClick={() => {
                    removeClass(id);
                  }}
                  tooltipMessage='Remove from party roster.'
                  tooltipPlacement='right'
                  tooltipClassName='tooltip'
                >
                  <FontAwesomeIcon icon={faXmark} size='xl' />
                </TooltipButton>
                <TooltipButton
                  className='btn btn-sm btn-dark promote'
                  onClick={() => {
                    const { supportTreasures } = allClasses[id];
                    const treasuresToUpdate = supportTreasures
                      .filter((id) => !(id in currentTreasures))
                      .reduce((a, id) => ({ ...a, [id]: 1 }), {});
                    attemptUpdateTreasures(treasuresToUpdate);
                  }}
                  tooltipMessage='Try adding supported treasures to promote.'
                  tooltipPlacement='right'
                  tooltipClassName='tooltip'
                >
                  <FontAwesomeIcon icon={faAnglesUp} size='xl' />
                </TooltipButton>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

PartyRoster.propTypes = {
  className: PropTypes.string,
};
