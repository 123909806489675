import { createAction, createSlice } from '@reduxjs/toolkit';
import classesData from '../data/class.json';
import treasuresData from '../data/treasures.json';
import { buildTags } from '../utils';

export const loadClasses = createAction('classes/load', () => {
  return {
    payload: {
      classesData,
      treasuresData,
    },
  };
});

const classesSlice = createSlice({
  name: 'classes',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadClasses, (_state, action) => {
      return action.payload?.classesData;
    });
  },
});

export const {} = classesSlice.actions;
export const classesReducer = classesSlice.reducer;

const taggedClassesSlice = createSlice({
  name: 'tagged_classes',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadClasses, (_state, action) => {
      const { payload: { classesData, treasuresData } = {} } = action;
      return buildTags(
        classesData,
        (
          {
            name,
            classDomains,
            abilityDomains,
            promotion,
            supportTreasures,
            tags,
          },
          builder,
        ) => {
          builder.add(name);
          builder.addList(classDomains);
          builder.addList(abilityDomains);
          builder.add(promotion?.name);
          builder.addList(
            supportTreasures?.map((t) => treasuresData?.[t]?.name),
          );
          builder.addList(tags);
        },
      );
    });
  },
});

export const {} = taggedClassesSlice.actions;
export const taggedClassesReducer = taggedClassesSlice.reducer;
