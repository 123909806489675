import './BuildInfo.sass';
import PropTypes from 'prop-types';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { BuildCodeModal } from './BuildCodeModal';
import { NameInput } from './NameInput';
import { TooltipButton } from './TooltipButton';
import { useBuilds, useCurrentBuild } from '../hooks';

export const BuildInfo = (props) => {
  const { className } = props;
  const {
    currentId,
    currentName,
    currentClasses,
    currentTreasures,
    changeId,
    changeName,
  } = useCurrentBuild();
  const { addBuild, updateBuild } = useBuilds();

  return (
    <div
      className={classNames(
        'build-info d-flex flex-row justify-content-end align-items-center gap-4',
        className,
      )}
    >
      <NameInput
        className='name-input'
        placeholder='NEW BUILD'
        value={currentName}
        onChange={changeName}
      />
      <TooltipButton
        flat
        className='save-button'
        tooltipMessage='Saved!'
        tooltipTrigger='click'
        tooltipPlacement='top'
        tooltipClassName='tooltip'
        tooltipDelay={{
          hide: 800,
        }}
        onClick={() => {
          if (!currentId) {
            const id = addBuild(currentName, currentClasses, currentTreasures);
            changeId(id);
          } else {
            updateBuild(
              currentId,
              currentName,
              currentClasses,
              currentTreasures,
            );
          }
        }}
      >
        <FontAwesomeIcon icon={faSave} />
      </TooltipButton>
      <BuildCodeModal />
    </div>
  );
};

BuildInfo.propTypes = {
  className: PropTypes.string,
};
