import PropTypes from 'prop-types';
import classNames from 'classnames';
import { enhance as e } from './EnhanceText';

export const DomainDisplay = (props) => {
  const { domains, vertical, className } = props;

  if (!domains) {
    return null;
  }

  return vertical ? (
    <div className={classNames('d-flex flex-column', className)}>
      {domains.map((a, i) => (
        <span key={i}>{e(a.toUpperCase())}</span>
      ))}
    </div>
  ) : (
    <span className={classNames('nowrap', className)}>
      {e(domains.map((d) => d.toUpperCase()).join(', '))}
    </span>
  );
};

DomainDisplay.propTypes = {
  className: PropTypes.string,
  domains: PropTypes.arrayOf(PropTypes.string),
  vertical: PropTypes.bool,
};
