import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { load, remove, save, update } from '../store/buildsSlice';

export const useBuilds = () => {
  const dispatch = useDispatch();

  const builds = useSelector((state) => state.builds);
  const addBuild = (name, classes, treasures) => {
    const id = nanoid();
    dispatch(update(id, name, classes, treasures));
    dispatch(save());
    return id;
  };
  const updateBuild = (id, name, classes, treasures) => {
    dispatch(update(id, name, classes, treasures));
    dispatch(save());
  };
  const removeBuild = (id) => {
    dispatch(remove(id));
    dispatch(save());
  };
  const loadBuilds = () => dispatch(load());

  return {
    builds,
    addBuild,
    updateBuild,
    removeBuild,
    loadBuilds,
  };
};
