import './TreasureTree.sass';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { enhance as e } from './EnhanceText';
import { TreasureLevelSelector } from './TreasureLevelSelector';
import { useCurrentBuild, useTreasures } from '../hooks';

const BAR_WIDTH = '0.6rem';
const BAR_SEGMENT_HEIGHT = '1.2rem';
const BAR_SEGMENTS_PAST_60 = 5;
const REGULAR_MAX_LEVEL = 60;

export const TreasureTree = (props) => {
  const { className } = props;
  const { treasureTree: { levels, boss, quest, chest } = {}, treasures } =
    useTreasures();
  const {
    currentTreasures,
    currentLevel,
    treasureDomains,
    treasureAbilityDomains,
    promotionTreasures,
    attemptUpdateTreasure,
  } = useCurrentBuild();

  const outOfTreasureRange = (treasureId) => {
    const { obtain: { start, end } = {} } = treasures[treasureId] ?? {};
    if (!start || !end) {
      return false;
    }

    // * Add an exception allows deselecting at 60,
    // * due to the gap an no overlap between the
    // * last tier and elixirs.
    if (currentLevel === REGULAR_MAX_LEVEL + 1 && end === REGULAR_MAX_LEVEL) {
      return false;
    }

    return currentLevel < start || currentLevel > end;
  };

  const withinRange = (start, end) => {
    if (start && end) {
      return start <= currentLevel && end >= currentLevel;
    }

    return false;
  };

  const textCapitalize = (text) => {
    return String(text).charAt(0).toUpperCase() + String(text).slice(1);
  };

  const renderSelectors = (treasureList, hoverCardSide) =>
    treasureList.map((treasureId, index) => (
      <TreasureLevelSelector
        key={index}
        treasureId={treasureId}
        value={currentTreasures[treasureId] ?? 0}
        maxValue={treasures[treasureId].levels}
        hoverCardSide={hoverCardSide}
        onChangeValue={(selectedLevel) =>
          attemptUpdateTreasure(treasureId, selectedLevel)
        }
        locked={outOfTreasureRange(treasureId)}
        promotes={promotionTreasures[treasureId]}
      />
    ));

  return (
    <div className={classNames('treasure-tree d-flex flex-column', className)}>
      <div className='info d-flex flex-column px-3 px-xxl-5 pb-2 mb-2 sticky-top'>
        <h2>Level {currentLevel}</h2>
        <div className='d-flex flex-column gap-2'>
          {Object.keys(treasureDomains)?.length > 0 && (
            <div className='d-flex flex-row gap-2'>
              <span className='domain-heading nowrap'>Treasure Domains</span>
              <div className='d-flex flex-row align-items-center flex-wrap gap-2'>
                {Object.entries(treasureDomains).map(([domain, count], i) => (
                  <span key={i} className='treasure-domain nowrap'>
                    {textCapitalize(`${domain} x${count}`)}
                  </span>
                ))}
              </div>
            </div>
          )}
          {Object.keys(treasureAbilityDomains)?.length > 0 && (
            <div className='d-flex flex-row gap-2'>
              <span className='domain-heading nowrap'>Ability Domains</span>
              <div className='d-flex flex-row align-items-center flex-wrap gap-2'>
                {Object.entries(treasureAbilityDomains).map(
                  ([domain, count], i) => (
                    <span key={i} className='ability-domain nowrap'>
                      {textCapitalize(`${domain} x${count}`)}
                    </span>
                  ),
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='container d-flex flex-row mx-3 mx-xxl-5'>
        <div className='d-flex flex-column gap-2'>
          <div
            className='levels'
            style={{
              width: `38rem`,
              height: `calc(${REGULAR_MAX_LEVEL + BAR_SEGMENTS_PAST_60} * ${BAR_SEGMENT_HEIGHT} + 2rem)`,
            }}
          >
            {Array.from(levels.entries()).map(([start, ends], startIndex) => (
              <div
                key={startIndex}
                style={{
                  position: 'absolute',
                  top: `calc(${start} * ${BAR_SEGMENT_HEIGHT}`,
                }}
                className='d-flex flex-row gap-5'
              >
                {Object.entries(ends).map(([end, ts], endIndex) => (
                  <div
                    key={endIndex}
                    className='d-flex flex-row align-items-start'
                  >
                    <div
                      className={classNames(
                        `bar bar${startIndex} d-flex flex-column justify-content-between align-items-center`,
                        { glow: withinRange(start, end) },
                      )}
                      style={{
                        width: BAR_WIDTH,
                        height: `calc(${end == 99 ? BAR_SEGMENTS_PAST_60 : end - start} * ${BAR_SEGMENT_HEIGHT}`,
                      }}
                    >
                      <span
                        className={classNames('numbers', {
                          glow: withinRange(start, end),
                        })}
                      >
                        {start}
                      </span>
                    </div>
                    <div
                      className={classNames('d-flex flex-wrap ms-3', {
                        'flex-row gap-2': endIndex === 0,
                        'flex-column gap-2': endIndex > 0,
                      })}
                      style={{
                        maxWidth: (() => {
                          switch (true) {
                            case [2, 5, 7].includes(startIndex):
                              return '28rem';
                            case 8 === startIndex:
                              return '18rem';
                            default:
                              return undefined;
                          }
                        })(),
                      }}
                    >
                      {renderSelectors(ts, endIndex > 0 ? 'left' : undefined)}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className='d-flex flex-row gap-4'>
          {boss && (
            <div className='d-flex flex-column gap-2'>
              {Array.from(boss.entries()).map(([b, ts], i) => (
                <div key={i} className='d-flex flex-column'>
                  <span className='headings'>{e(`BOSS ${b}`)}</span>
                  <div className='d-flex flex-row gap-2'>
                    {renderSelectors(ts)}
                  </div>
                </div>
              ))}
            </div>
          )}
          {quest && (
            <div className='d-flex flex-column gap-2'>
              {Array.from(quest.entries()).map(([q, ts], i) => (
                <div key={i} className='d-flex flex-column'>
                  <span className='headings'>{e(`QUEST ${q}`)}</span>
                  <div className='d-flex flex-row gap-2'>
                    {renderSelectors(ts)}
                  </div>
                </div>
              ))}
            </div>
          )}
          {chest && (
            <div className='d-flex flex-column'>
              <span className='headings'>{e('CHESTS')}</span>
              {renderSelectors(chest)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TreasureTree.propTypes = {
  className: PropTypes.string,
};
