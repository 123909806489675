import './PlannerScreen.sass';
import { BuildInfo } from './BuildInfo';
import { BuildsControl } from './BuildsControl';
import { PartyRoster } from './PartyRoster';
import { PlannerFilter } from './PlannerFilter';
import { TreasureTree } from './TreasureTree';

export const PlannerScreen = () => {
  return (
    <div className='planner-screen'>
      <h1>
        Build Planner <span className='danger'>[WIP]</span>
      </h1>
      <div className='w-100 d-flex flex-row gap-3'>
        <PlannerFilter className='flex-grow-1' />
        <BuildsControl />
      </div>
      <BuildInfo className='mt-3 mx-3' />
      <div className='d-flex flex-column flex-xxl-row gap-1'>
        <PartyRoster />
        <TreasureTree />
      </div>
    </div>
  );
};
