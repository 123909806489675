import './TreasureResults.sass';
import { AbilityEffectDisplay } from './AbilityEffectDisplay';
import { DomainDisplay } from './DomainDisplay';
import { enhance as e } from './EnhanceText';
import { ObtainedFromDisplay } from './ObtainedFromDisplay';
import { TreasureImage } from './TreasureImage';

const ICON_SIZE = 40;

export const TreasureResults = (props) => {
  const { treasures } = props ?? {};

  return (
    <table className='table table-dark treasure-results'>
      <thead className='sticky-top'>
        <tr>
          <th scope='col'>#</th>
          <th scope='col'>Treasure</th>
          <th scope='col'>Levels</th>
          <th scope='col' className='nowrap'>
            Obtained from
          </th>
          <th scope='col'>Domains</th>
          <th scope='col' className='nowrap'>
            Ability Effects
          </th>
        </tr>
      </thead>
      <tbody>
        {treasures &&
          Object.entries(treasures)?.map(
            (
              [
                id,
                {
                  name,
                  description,
                  levels,
                  obtain,
                  treasureDomain,
                  abilityDomains,
                  effect,
                },
              ],
              index,
            ) => (
              <tr key={id}>
                <th scope='row'>{index + 1}</th>
                <td>
                  <div className='d-flex flex-row'>
                    <TreasureImage
                      treasureId={id}
                      width={ICON_SIZE}
                      height={ICON_SIZE}
                    />
                    <div className='ms-3 d-flex flex-column'>
                      <b className='name'>{name}</b>
                      <span>{e(description)}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <span>{levels}</span>
                </td>
                <td>
                  <ObtainedFromDisplay {...obtain} />
                </td>
                <td>
                  <div className='d-flex flex-column'>
                    <DomainDisplay domains={[treasureDomain]} />
                    <DomainDisplay domains={abilityDomains} />
                  </div>
                </td>
                <td>
                  <AbilityEffectDisplay {...effect} />
                </td>
              </tr>
            ),
          )}
      </tbody>
    </table>
  );
};
