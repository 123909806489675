import { enhance as e } from './EnhanceText';

export const AbilityEffectDisplay = (props) => {
  const { physical, magical, cooldown, mp } = props ?? {};

  return (
    <div className='d-flex flex-column'>
      {physical && (
        <span>
          <span className='nowrap'>
            <b>PHYS:</b>
            {e(
              typeof physical === 'object'
                ? ` ${physical.start} ▶ ${physical.end}%`
                : ` ${physical}%`,
            )}
          </span>
          {physical?.scale?.value && physical?.scale?.heroes && (
            <span className='mx-3 nowrap'>
              {e(
                `+${physical.scale.value}% for each ${physical.scale.heroes.map((h) => h.toUpperCase()).join(', ')} hero`,
              )}
            </span>
          )}
        </span>
      )}
      {magical && (
        <span className='nowrap'>
          <b>MAG:</b>
          <span>
            {e(
              typeof magical === 'object'
                ? ` ${magical.start} ▶ ${magical.end}%`
                : ` ${magical}%`,
            )}
          </span>
          {magical?.scale?.value && magical?.scale?.heroes && (
            <span className='mx-3'>
              {e(
                `+${magical.scale.value}% for each ${magical.scale.heroes.map((h) => h.toUpperCase()).join(', ')} hero`,
              )}
            </span>
          )}
        </span>
      )}
      {cooldown && (
        <span className='nowrap'>
          <b>Cooldown:</b>
          {e(
            typeof cooldown === 'object'
              ? ` ${cooldown.start.toFixed(1)} ▶ ${cooldown.end.toFixed(1)}s`
              : ` ${cooldown.toFixed(1)}s`,
          )}
        </span>
      )}
      {mp && (
        <span className='nowrap'>
          <b>MP:</b>
          {e(
            typeof mp === 'object'
              ? ` ${mp.start.toFixed(1)} ▶ ${mp.end.toFixed(1)}`
              : ` ${mp.toFixed(1)}`,
          )}
        </span>
      )}
    </div>
  );
};
