import { Outlet } from 'react-router-dom';
import { Nav } from './Nav';

export const AppLayout = () => {
  return (
    <div className='container-fluid'>
      <header>
        <Nav />
      </header>
      <main className='mt-3'>
        <Outlet />
      </main>
    </div>
  );
};
