import './EnhanceText.sass';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

// * Order matters.
const STATUS_NAMES = [
  'HP REGENERATION',
  'HP',
  'PHYSICAL ATTACK',
  'MAGICAL ATTACK',
  'CRITICAL', // "CRITICAL RATE" in-game.
  'DEFENSE',
  'COOLDOWN', // "COOLDOWN REDUCTION" in-game.
  'LUCK',
  'REPUTATION',
];

const CLASS_DOMAIN_NAMES = [
  'EXPERT',
  'WISDOM',
  'SENSE',
  'BRAVE',
  'FAITH',
  'INSPIRE',
];

const ABILITY_DOMAIN_NAMES = [
  'MELEE',
  'PROJECTILE',
  'CAST',
  'SUMMON',
  'ELEMENTAL',
  'WEAPONRY',
];

const TREASURE_DOMAIN_NAMES = ['SYMBOL', 'RELIC', 'ARTIFACT'];

const OBTAIN_METHOD_BOSS_REGEX = /BOSS [0-9]+/;
const OBTAIN_METHOD_QUEST_REGEX = /QUEST [0-9]+/;
const OBTAIN_METHOD_CHEST_NAME = 'CHESTS';

const NAMES_LIST = [
  ...STATUS_NAMES,
  ...CLASS_DOMAIN_NAMES,
  ...ABILITY_DOMAIN_NAMES,
  ...TREASURE_DOMAIN_NAMES,
  OBTAIN_METHOD_BOSS_REGEX.source,
  OBTAIN_METHOD_QUEST_REGEX.source,
  OBTAIN_METHOD_CHEST_NAME,
];

// * Avoid using capture groups, as they produce double results.
const NUMBER_REGEX = /[-+]{0,1}[0-9]+(?:\.[0-9]+){0,1}%{0,1}|▶/;
const TERM_REGEX = new RegExp(`(?:\<[A-Z ]+\>|${NAMES_LIST.join('|')})`);
const COMBINED_REGEX = new RegExp(
  `(${NUMBER_REGEX.source}|${TERM_REGEX.source})`,
);

export const EnhanceText = (props) => {
  const { children } = props;

  if (typeof children !== 'string') {
    return null;
  }

  const parts = children.split(COMBINED_REGEX).filter((p) => p);

  return (
    <>
      {parts.map((p, i) => {
        if (TERM_REGEX.test(p)) {
          const styleClass = (() => {
            switch (true) {
              case STATUS_NAMES.includes(p):
                return 'enhance-status';
              case CLASS_DOMAIN_NAMES.includes(p):
                return 'enhance-class-domain';
              case ABILITY_DOMAIN_NAMES.includes(p):
                return 'enhance-ability-domain';
              case TREASURE_DOMAIN_NAMES.includes(p):
                return 'enhance-treasure-domain';
              case OBTAIN_METHOD_BOSS_REGEX.test(p):
                return `enhance-obtain-boss-${/([0-9]+)/.exec(p)[1]}`;
              case OBTAIN_METHOD_QUEST_REGEX.test(p):
                return `enhance-obtain-quest-${/([0-9]+)/.exec(p)[1]}`;
              case OBTAIN_METHOD_CHEST_NAME === p:
                return 'enhance-obtain-chests';
              default:
                return 'enhance-status-effect';
            }
          })();

          return (
            <span key={i} className={styleClass}>
              {p}
            </span>
          );
        } else if (NUMBER_REGEX.test(p)) {
          return (
            <span key={i} className='enhance-numbers'>
              {p}
            </span>
          );
        } else {
          return <Fragment key={i}>{p}</Fragment>;
        }
      })}
    </>
  );
};

EnhanceText.propTypes = {
  children: PropTypes.string.isRequired,
};

export const enhance = (text) => <EnhanceText>{text}</EnhanceText>;
