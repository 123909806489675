import { useState } from 'react';
import { ClassResults } from './ClassResults';
import { FilterInput } from './FilterInput';
import { useClasses } from '../hooks';

export const ClassScreen = () => {
  const { filterClasses, classes } = useClasses();
  const [filteredClasses, setFilteredClasses] = useState(classes);

  return (
    <div className='d-flex flex-column gap-1'>
      <h1 className='ms-2'>Classes</h1>
      <div className='ms-1 me-5 mb-3'>
        <div className='mb-1'>
          <FilterInput
            placeholder={`filter classes, ex. 'martial artist' or 'wisdom, projectile, elemental, symbol' or 'mirage shield'`}
            onChange={(searchText) => {
              setFilteredClasses(
                searchText ? filterClasses(searchText) : classes,
              );
            }}
          />
        </div>
        <span className='ms-1'>
          Filter by class and promotion names, domains, support treasures, and
          leadership skill domains. Filter with multiple terms by separating
          text with commas.
        </span>
      </div>
      <div>
        <ClassResults classes={filteredClasses} />
        {filteredClasses && !Object.keys(filteredClasses)?.length && (
          <div>No results.</div>
        )}
      </div>
    </div>
  );
};
