import './BuildCodeModal.sass';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { faDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JsonTextArea } from './JsonTextArea';
import { TooltipButton } from './TooltipButton';
import { useCurrentBuild } from '../hooks';

export const BuildCodeModal = () => {
  const { currentBuild, load } = useCurrentBuild();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <TooltipButton
        flat
        className='build-code-button'
        onClick={() => {
          setShowModal(true);
        }}
        tooltipMessage='Share or load build'
      >
        <FontAwesomeIcon className='share-icon' icon={faShare} /> /{' '}
        <FontAwesomeIcon className='load-icon' icon={faDownload} />
      </TooltipButton>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        data-bs-theme='dark'
      >
        <Modal.Body>
          <JsonTextArea
            label='Copy to share build -OR- paste existing to load build.'
            value={currentBuild}
            onChange={(value) => {
              load(value);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
