import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadTreasures as load } from '../store/treasuresSlice';
import { filterSearch } from '../utils';

export const useTreasures = () => {
  const dispatch = useDispatch();

  const treasures = useSelector((state) => state.treasures);
  const taggedTreasures = useSelector((state) => state.taggedTreasures);
  const tags = useMemo(() => Object.keys(taggedTreasures), [taggedTreasures]);
  const treasureTree = useMemo(() => {
    const result = Object.entries(treasures)?.reduce(
      (aggregate, [id, treasure]) => {
        const { obtain } = treasure;
        if ('start' in obtain && 'end' in obtain) {
          const { start, end } = obtain;
          return {
            ...aggregate,
            levels: {
              ...aggregate.levels,
              [start]: {
                // Include any previously added start levels.
                ...(aggregate.levels?.[start] ?? {}),
                // Append to existing list if there is one.
                [end]: [...(aggregate.levels?.[start]?.[end] ?? []), id],
              },
            },
          };
        } else if ('boss' in obtain) {
          const { boss } = obtain;
          return {
            ...aggregate,
            boss: {
              ...aggregate.boss,
              [boss]: [...(aggregate.boss?.[boss] ?? []), id],
            },
          };
        } else if ('quest' in obtain) {
          const { quest } = obtain;
          return {
            ...aggregate,
            quest: {
              ...aggregate.quest,
              [quest]: [...(aggregate.quest?.[quest] ?? []), id],
            },
          };
        } else if ('chest' in obtain) {
          return {
            ...aggregate,
            chest: [...aggregate.chest, id],
          };
        } else {
          return aggregate;
        }
      },
      {
        levels: {},
        boss: {},
        quest: {},
        chest: [],
      },
    );

    return Object.entries(result).reduce((aggregate, [key, value]) => {
      // TODO: Sort end levels, maybe.
      const sortedValue = (() => {
        switch (key) {
          case 'chest':
            return value;
          default:
            return new Map(
              Object.entries(value).sort(
                ([key1], [key2]) => parseInt(key1) - parseInt(key2),
              ),
            );
        }
      })();
      return {
        ...aggregate,
        [key]: sortedValue,
      };
    }, {});
  }, [treasures]);

  const loadTreasures = () => dispatch(load());

  const filterTreasures = (term, ignoreTerms = []) =>
    filterSearch(tags, taggedTreasures, treasures, term, ignoreTerms);

  return {
    treasures,
    treasureTree,
    loadTreasures,
    filterTreasures,
  };
};
