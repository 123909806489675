import './ClassIcon.sass';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import { ClassCard } from './ClassCard';
import { ClassImage } from './ClassImage';
import { useClasses } from '../hooks';

export const ClassIcon = (props) => {
  const { classId, ...rest } = props;
  const { classes } = useClasses();
  const iconContainerRef = useRef();
  const [show, setShow] = useState();

  // * Support touch screen.
  // * Reference: https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
  useEffect(() => {
    const current = iconContainerRef.current;
    if (current && 'ontouchstart' in window) {
      const handleTouch = (e) => {
        setShow(current.contains(e.target) ? true : undefined);
      };

      // * In the event a device supports both mouse and touch, moving the mouse
      // * into another card should hide the hover card for the current card.
      const handleMouseEnter = (e) => {
        if (show && !current.contains(e.target)) {
          setShow(undefined);
        }
      };

      document.addEventListener('touchstart', handleTouch);
      document.addEventListener('mouseenter', handleMouseEnter);
      return () => {
        document.removeEventListener('touchstart', handleTouch);
        document.removeEventListener('mouseenter', handleMouseEnter);
      };
    }
  }, []);

  return (
    <HoverCard.Root openDelay={0} closeDelay={0}>
      <HoverCard.Trigger>
        <div ref={iconContainerRef}>
          <ClassImage classId={classId} className='class-icon' {...rest} />
        </div>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content style={{ zIndex: 99 }}>
          {(() => {
            const foundClass = classes[classId];

            return foundClass ? (
              <ClassCard id={classId} {...foundClass} />
            ) : null;
          })()}
          <HoverCard.Arrow width={16} height={10} />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

ClassIcon.propTypes = {
  classId: PropTypes.string.isRequired,
};
